<template>
    <div class="imagegrid__wrapper">
		<!-- <div class="imagegrid__item" v-for="(image, key) in images" :key="key">
            <div class="imagegrid__item-image" :style="{backgroundImage:`url(${require('@/assets/images/'+ image.bg)})`}"></div>
		</div> -->

		<gallery :images="images" :index="index" @close="index = null"></gallery>

        <div
        class="imagegrid__item"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="index = imageIndex"
        >
            <div class="imagegrid__item-image" :style="{ backgroundImage: 'url(' + image + ')'}">
        </div>
        </div>
    </div>
</template>

<script>
// Components
import Gallery from '@/components/Gallery'

// Export
export default {
	name: 'imageGrid',
	props: {
		images: {
			type: Array,
			default: () => ([
				'https://dummyimage.com/800/ffffff/000000',
				'https://dummyimage.com/1600/ffffff/000000',
				'https://dummyimage.com/1280/000000/ffffff',
				'https://dummyimage.com/400/000000/ffffff',
			]),
		},
	},
	components: {
		Gallery,
	},
	data: () => ({
		index: null,
	}),
}
</script>

<style lang="scss" scoped>
.imagegrid {

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        background: url(../assets/images/black_background.jpg) #1e1e1e scroll repeat;
    }

    &__item {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        text-align: center;
        position: relative;
        overflow: hidden;
        //height: 400px;
        //@include gutter('padding',.25);

        display: flex;
        flex: 33.333%;
        max-width: 33.333%;
        flex-wrap: wrap;
        @include ratio('square');

        align-items: center;
        justify-content: center;

        @media screen and (max-width: map-get($breakpoints, 'lg')) {
            flex: 50%;
            max-width: 50%;
        }

        @media screen and (max-width: map-get($breakpoints, 'sm')) {
            flex: 100%;
            max-width: 100%;
        }

        &-image {
            @include ratio('square');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            height: 100%;
            width: 100%;
            transform: scale(1);
            transition: opacity 350ms  $easing-cubic 0ms, transform 10s;
        }

        &-3 {
            flex: 25%;
        }

        &-6 {
            flex: 50%;
        }

        h4 {
            position: relative;
            z-index: 1;
            text-shadow: 0 2px 4px  rgba(0,0,0,0.5);
            color: color_('white');

        }

        &:after {
            background: rgba(0,0,0,0);
            content:"";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom:0;
            transition: all .5s $easing-cubic;
        }

        &:hover {
            cursor: pointer;

            &:after {
                background: rgba(255,255,255,.05);
            }

            .imagegrid__item-image {
                transform: scale(1.15);
            }
        }
    }
}
</style>

